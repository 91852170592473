<template>
  <div>
    <div>
      <v-toolbar
        prominent
      >
        <v-btn
          v-if="formConfig"
          color="error"
          @click="submitForm()"
        >
          Close
        </v-btn>
        <v-spacer></v-spacer>
        <v-switch
          v-model="status"
          color="primary"
          :label="status ? 'Active' : 'Inactive'"
        >
        </v-switch>
        <v-spacer></v-spacer>

        <task-type-select
          :multiple="false"
          :task-type="formConfig.formGroup"
          @task-type-select="updateType"
        ></task-type-select>

        <v-spacer></v-spacer>

        <v-switch
          v-model="formConfig.flowsheet"
          color="primary"
          :label="!formConfig.flowsheet ? 'Form' : 'Flowsheet'"
        >
        </v-switch>
        <v-spacer></v-spacer>

        <v-btn
          color="primary"
          @click="isSubmit = true, submitForm()"
        >
          Save
        </v-btn>
      </v-toolbar>
    </div>

    <br>

    <v-card
      v-if="!isRenderer"
    >
      <br>
      <FormBuilder
        v-model="formData"
      ></FormBuilder>
    </v-card>

    <br>

    <div>
      <button
        class="btn btn-info mr-2"
        @click="viewRenderer"
      >
        <span v-show="isRenderer">
          Back to Builder
        </span>
        <span v-show="!isRenderer">
          View Form from Configuration
        </span>
      </button>
    </div>

    <br>

    <div
      v-if="isRenderer"
    >
      <form-render :form-data="formData"></form-render>
    </div>
  </div>
</template>

<script>
import formService from '@/services/FormService'
import useFormService from '@/store/useFormService'

import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import TaskTypeSelect from '@/components/task/TaskTypeSelect.vue'
import FormRender from './FormRender.vue'

export default {
  name: 'ClientFormBuilder',
  components: {
    FormRender,
    TaskTypeSelect,
  },
  props: {
    formConfig: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    isRenderer: false,
    formInputData: null,
    isShowData: false,
    readOnly: false,
    isSubmit: false,
    group: 'Medical',
    status: true,
  }),
  setup(props, { emit }) {
    const FORM_APP = 'app-form'

    // Register module
    if (!store.hasModule(FORM_APP)) {
      store.registerModule(FORM_APP, formService)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(FORM_APP)) store.unregisterModule(FORM_APP)
    })
    const { postFormConfig } = useFormService()
    let { formData } = useFormService()

    console.log('Form Config', props.formConfig)

    if (props.formConfig.formName === undefined) {
      console.log('undefined')
      props.formConfig.formGroup = 'Medical'
      formData = props.formConfig
      formData.isActive = true
      formData.formConfig.headline = 'Form Title'
      formData.formConfig.isShowHeadline = true
    } else {
      // this.styleId = props.formConfig.id
      formData = props.formConfig.formConfiguration
      formData.isActive = props.formConfig.isActive
    }

    const closeForm = () => {
      console.log('Submit')
      emit('update-dialog', 'edit')

      // this.$router.push({ name: 'site-form-list' })
    }

    return {
      postFormConfig,
      formData,

      closeForm,
    }
  },
  methods: {
    updateType(item) {
      this.group = item.display
      //console.log('Group', this.group)
      //console.log(this.status)
    },
    viewRenderer() {
      if (!this.isRenderer) {
        // this.setData()
        this.isRenderer = true

        return
      }
      this.isRenderer = false
    },
    submitForm() {
      let styleId
      //console.log(this.status)

      if (this.formConfig.id != null || this.formConfig.id !== undefined) {
        styleId = this.formConfig.id
      }

      //console.log('submit', this.formData, styleId, this.status, this.group)

      if (this.isSubmit) {
        this.postFormConfig(this.formData, styleId, this.status, this.group, this.formConfig.flowsheet)
      }
      this.closeForm()
    },
  },
}
</script>
